// Generated by Framer (9f2fb1c)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, RichText, SmartComponentScopedContainer, useComponentViewport, useLocaleInfo, useVariantState, withCodeBoundaryForOverrides, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import { withNoActiveLicense } from "https://framerusercontent.com/modules/3A7VAuhQUt26uZbnJnpi/D8IqdkD4MiwKCVD8sRCk/FramerAuth.js";
import CMSLock from "https://framerusercontent.com/modules/yaimHesWwF6a4XOmUDCI/lE0VFwQURKk9gJuluh4d/vf1F7llpp.js";
import * as sharedStyle from "../css/xV5i52TGE";
const CMSLockFonts = getFonts(CMSLock);
const MotionDivWithNoActiveLicense15yg4p5 = withCodeBoundaryForOverrides(motion.div, {nodeId: "mCEgAnbo_", override: withNoActiveLicense, scopeId: "sdIaGP3Jx"});

const serializationHash = "framer-yUXoL"

const variantClassNames = {bWrzSm5Iw: "framer-v-10hkhx4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({duration, height, id, locked, width, ...props}) => { return {...props, bAsn2nMFX: duration ?? props.bAsn2nMFX ?? "8:02", IeIgT0ykj: locked ?? props.IeIgT0ykj ?? true} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;locked?: boolean;duration?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, IeIgT0ykj, bAsn2nMFX, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "bWrzSm5Iw", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-10hkhx4", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"bWrzSm5Iw"} ref={refBinding} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1x8tv2m"} data-styles-preset={"xV5i52TGE"}>8:02</motion.p></React.Fragment>} className={"framer-mgwjk0"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"qpECh6e07"} text={bAsn2nMFX} verticalAlignment={"top"} withExternalLayout/><MotionDivWithNoActiveLicense15yg4p5 className={"framer-15yg4p5"} layoutDependency={layoutDependency} layoutId={"mCEgAnbo_"}>{IeIgT0ykj && (<ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-1p0u7zm-container"} isModuleExternal layoutDependency={layoutDependency} layoutId={"bYqaDyGER-container"} nodeId={"bYqaDyGER"} rendersWithMotion scopeId={"sdIaGP3Jx"}><CMSLock height={"100%"} id={"bYqaDyGER"} layoutId={"bYqaDyGER"} style={{height: "100%", width: "100%"}} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider>)}</MotionDivWithNoActiveLicense15yg4p5></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-yUXoL.framer-10onw56, .framer-yUXoL .framer-10onw56 { display: block; }", ".framer-yUXoL.framer-10hkhx4 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-yUXoL .framer-mgwjk0 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-yUXoL .framer-15yg4p5 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-yUXoL .framer-1p0u7zm-container { flex: none; height: 24px; position: relative; width: 24px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-yUXoL.framer-10hkhx4, .framer-yUXoL .framer-15yg4p5 { gap: 0px; } .framer-yUXoL.framer-10hkhx4 > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-yUXoL.framer-10hkhx4 > :first-child, .framer-yUXoL .framer-15yg4p5 > :first-child { margin-left: 0px; } .framer-yUXoL.framer-10hkhx4 > :last-child, .framer-yUXoL .framer-15yg4p5 > :last-child { margin-right: 0px; } .framer-yUXoL .framer-15yg4p5 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 62
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"IeIgT0ykj":"locked","bAsn2nMFX":"duration"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 * @framerColorSyntax true
 */
const FramersdIaGP3Jx: React.ComponentType<Props> = withCSS(Component, css, "framer-yUXoL") as typeof Component;
export default FramersdIaGP3Jx;

FramersdIaGP3Jx.displayName = "CMS / Lesson Details";

FramersdIaGP3Jx.defaultProps = {height: 24, width: 62};

addPropertyControls(FramersdIaGP3Jx, {IeIgT0ykj: {defaultValue: true, title: "Locked", type: ControlType.Boolean}, bAsn2nMFX: {defaultValue: "8:02", displayTextArea: false, title: "Duration", type: ControlType.String}} as any)

addFonts(FramersdIaGP3Jx, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...CMSLockFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})